* {
  margin: 0;
  padding: 0;
  font-family: Arial,Helvetica,sans-serif;
}
 html, body, #root {
  height: 100%;
 }

 body {
  background-color:#f3f3f3;
 }